.notification {
    padding: 15px;
    margin: 10px;
    color: white;
    display: flex;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-family: sans-serif;
    justify-content: space-between;
    width: fit-content;
}

.success {
    background-color: #4caf50;
}

.info {
    background-color: #2196f3;
}

.warning {
    background-color: #ff9800;
}

.error {
    background-color: #f44336;
}

.closeBtn {
    margin-left: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.top-right {
    position: fixed;
    top: 80px;
    right: 30px;
    z-index: 10000;
    cursor: pointer;
}

.top-left {
    position: fixed;
    top: 20px;
    left: 20px;
}

.bottom-right {
    position: fixed;
    bottom: 120px;
    right: 20px;
}

.bottom-right {
    position: fixed;
    bottom: 20px;
    left: 20px;
}